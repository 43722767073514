<template>
  <div class="number">
    <div class="search-wrap">
      <div class="title">
        会员单位
      </div>
      <div class="searchBtn">
        <searchCompany genreType="4" v-model="queryParams.companyName" @search="search" class="search-wrap-box" />
      </div>
    </div>
    <filterSearch @handleSearch="handleSearch" :isShow="isShow" />
    <div class="main">
      <div>
        <template v-for="(item,index) in companyList">
          <unitItem :number="number" :key="index" :companyInfo="item" :iconShow="2"/>
        </template>
      </div>
      <isLoadingTip v-if="loading" />
      <isAllDataTip v-if="companyList.length&&total === companyList.length&&!loading"  />
      <noDataImg v-if="companyList.length === 0"></noDataImg>
    </div>
  </div>
</template>

<script>
import searchCompany from "~tcb/components/company/unit/searchCompany";
import unitItem from "~tcb/components/company/unit/unitItem";
import filterSearch from '~tcb/components/common/filterSearch'
import isLoadingTip from '@/baseComponents/isLoadingTip'
import isAllDataTip from '@/baseComponents/isAllDataTip'

export default {
   metaInfo: {
    title: "会员单位",
  },
  name: "member",
  components: {
    searchCompany,
    unitItem,
    filterSearch,
    isLoadingTip,
    isAllDataTip
  },
  data() {
    return {
      currentPage: 0,
      total: 0,
      companyList:[],
      isShow:false,
      number:3,
      queryParams:{
        companyName:''
      },
      loading:false,
      filters:{}
    }
  },
  mounted() {
    this.getList(1);
    window.addEventListener("scroll", this.scrollEvent, false);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollEvent, false);
  },
  methods: {
    handleData(obj) {
      let arr = []
      for(let i in obj) {
        arr = arr.concat(obj[i])
      }
      return arr
    },
    handleCurrentChange() {
    },
    async getList(type){
      window.removeEventListener("scroll", this.scrollEvent, false);
      //type 1是搜索 2是下拉
      this.loading=true
      let params={}
      params.start=this.currentPage
      params.limit=10
      params.name=this.queryParams.companyName
      params.city_id=this.filters.city
      params.company_credit=this.filters.credit
      params.genre_ids=this.filters.unitType
      // params.certificate=20
      params.source=7
      params.genre_ids=4
      params.vip_type =2
      params.user_id=this.USER_ID
      // params.source_type=1
      let res=await this.$store.dispatch("API_company/getCompanyList",params)
      if (res.success){
        window.addEventListener("scroll", this.scrollEvent, false);
        res.data.forEach(item=>{
          item.business_classification_list=this.handleData(item.business_classification_info)
        })
        res.data.forEach((item,index)=>{
          if (item.genre_ids.length>1){
            item.genre_ids=item.genre_ids.filter(ee=>ee!='1')
          }
        })
        this.currentPage++
        this.loading=false
        if (type === 1){
          this.companyList=res.data
        }else{
          this.companyList=this.companyList.concat(res.data)
        }
        this.total=res.total
      }
    },
    search(){
      this.currentPage=0
      this.getList(1)
    },
    handleSearch(val){
      this.filters=val
      this.currentPage=0
      this.getList(1)
    },
    scrollEvent(){
      if (document.documentElement.scrollTop + document.documentElement.clientHeight>= (document.body.scrollHeight-354)) {
        if (this.total!=this.companyList.length) {
          this.getList(2)
        }
      }
    },
  }
}
</script>

<style lang="less" scoped>
.number{
  .search-wrap{
    display: flex;
    justify-content:space-between;
    .search-wrap-box {
      display: flex;
      padding-top: 10px;
      justify-content:space-between;
    }
  }
}
.title{
  font-size: 24px;
  font-weight: bold;
  color: #666666;
  margin-top: 13px;
  margin-bottom: 24px;
}
.main{
  margin-top: 20px;
  min-height:200px;
  position: relative;
}
</style>
